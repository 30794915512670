.NdProductItem {
    font-size: var(--table-font-size);
    color: var(--dark);
    font-weight: bold;
}

.Producer {
  color: var(--gray);
  font-weight: bold;
}
