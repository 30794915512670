.header-base__header {
    margin-bottom: 8px;
}

.header-base__nav {
  align-items: center;
}

.header-base__nav .alert {
  margin-bottom: 0;
  padding: 0;
  background: none;
  border: none;
  color: var(--dark);
  display: flex;
  align-items: center;
}
