.TableItem {
  display: grid;
  grid-template-areas: "header header" "order-bacs nd-product-list";
  grid-template-rows: 48px auto;
  grid-template-columns: 130px auto;
  column-gap: 16px;
  padding: 0rem 1rem 0.5rem;
}

.Header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.PaidBadge {
  color: white;
  font-size: 12px;
  border-radius: 18px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 12px;
}

.OrderBacs {
  grid-area: order-bacs;
  display: flex;
  flex-wrap: wrap;
  align-content: space-evenly;
  justify-content: space-evenly;
}

.NdProductList {
  grid-area: nd-product-list;
}

.NdProductItem {
}
